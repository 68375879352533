
import { useEffect } from 'react'
import CryptoJS from 'crypto-js';
//import dynamic from 'next/dynamic'
import { useDispatch } from 'react-redux';
import * as Actions from '../src/store/actions';

import Layout from '../src/layout/layout'
import Landing from '../src/components/landing/Landing'
//const Layout = dynamic(() => import('../src/layout/layout'))
//const Landing = dynamic(() => import('../src/components/landing/Landing'))

const encryptionKey = 'frontkeys';


// Descifrar datos en el cliente
function decrypt(encrypted) {
  try {

    var decryptedData = CryptoJS.AES.decrypt(encrypted, encryptionKey).toString(
      CryptoJS.enc.Utf8
    )

    return JSON.parse(decryptedData)
  } catch (error) {
    console.error('Error al descifrar los datos:', error);
    throw new Error('Fallo en la operación de descifrado');
  }
}


const Index = (props) => {


  const dispatch = useDispatch();

  useEffect(() => {
    //console.log(props.data)
    if (props.data && props.data.categorias && props.data.terminos) {
      dispatch(Actions.getCategorias(decrypt(props.data.categorias)))
      dispatch(Actions.updateCompara({ id: 'categoria', value: 'supermercado' }));
      dispatch(Actions.updateCompara({ id: 'termino', value: props.termino }));
      dispatch(Actions.getTerminos(decrypt(props.data.terminos)))
    }


  }, [props, dispatch]);


  return (
    <Layout {...props}>
      <Landing {...props} />
    </Layout>
  );
}


export async function getServerSideProps(context) {

  try {

    let api = process.env.REACT_APP_API_URL

    let categoriasUrl = api + '/svc/landing'
    const categoriasPage = await fetch(categoriasUrl)
    const categorias = await categoriasPage.json() || []

    let terminosUrl = api + '/compara/terminos'
    const terminosPage = await fetch(terminosUrl)
    const terminos = await terminosPage.json() || []

    const output = {
      //categorias: decrypt(categorias.data),
      //terminos: decrypt(terminos.data)
      categorias: categorias.data,
      terminos: terminos.data
    }

    //console.log('index ', categoriasUrl, terminosUrl)
    //console.log(output)
    //console.log('---------------')

    return {
      props: {
        pagename: '',
        //queryall: context.query.all,
        data: output,////page_otput.data || []
        termino: '',
        url: ''
      }
    }
  }
  catch (e) {
    //console.log(e)
    return {
      props: {}
    }
  }
}

export default Index